<template>
  <div v-if="data.predict_route_id">
    <h3>{{data.predict_route_id }}</h3>
    <v-row>
      <v-col cols="8">
        <v-card class="riding-data-card">
          <v-card-text>
            <v-container class="pa-0" v-if="routeInfo">
              <v-row>
                <StrategyChart :route-data="routeInfo" />
              </v-row>
              <v-row>
                <v-col>
                  <label>Distance</label>
                  <p class="value">
                    {{ data.riding_data.distance | MtoKm | RoundToTwo | NumberComma }}<small>km</small>
                  </p>
                  <!-- TODO: add edit input switch -->
                </v-col>
                <v-col>
                  <label>Duration</label>
                  <p class="value">
                    {{ data.riding_data.duration | FormatSeconds }}
                  </p>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label>elevation gain</label>
                  <p class="value">
                    {{ data.riding_data.elevation_gain | RoundToTwo | NumberComma }}<small>m</small>
                  </p>
                </v-col>
                <v-col>
                  <label>Calories</label>
                  <p class="value">
                    {{ data.riding_data.calories | RoundToTwo | NumberComma }}<small>Kcal</small>
                  </p>
                </v-col>
                <v-col>
                  <label>Energy output</label>
                  <p class="value">
                    {{ data.riding_data.energy_output | RoundToTwo | NumberComma }}<small>kj</small>
                  </p>
                </v-col>
                <v-col>
                  <label>TSS</label>
                  <p class="value">
                    {{ data.riding_data.TSS | RoundToTwo | NumberComma }}
                  </p>
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label>Avg. power</label>
                  <p class="value">
                    {{ data.riding_data.average_power | RoundToTwo | NumberComma }}<small>W</small>
                  </p>
                </v-col>
                <v-col>
                  <label>Avg. w/kg</label>
                  <p class="value">
                    {{ data.riding_data.average_tuili | RoundToTwo | NumberComma }}
                  </p>
                </v-col>
                <v-col>
                  <label>Avg. speed</label>
                  <p class="value">
                    {{ data.riding_data.average_speed | RoundToTwo | NumberComma }}<small>kph</small>
                  </p>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label>Max power</label>
                  <p class="value">
                    {{ data.riding_data.max_power | RoundToTwo | NumberComma }}<small>W</small>
                  </p>
                </v-col>
                <v-col>
                  <label>Max w/kg</label>
                  <p class="value">
                    {{ data.riding_data.max_tuili | RoundToTwo | NumberComma }}
                  </p>
                </v-col>
                <v-col>
                  <label>Max speed</label>
                  <p class="value">
                    {{ data.riding_data.max_speed| RoundToTwo | NumberComma }}<small>kph</small>
                  </p>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="strategy-card" :class="strategyUpdating? 'updating': ''">
          <v-card-title>Strategy</v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-row class="header-row">
                <v-col>Segment</v-col>
                <v-col>Avg. grade</v-col>
                <v-col>Distance</v-col>
                <v-col>Avg. power</v-col>
                <v-col>Avg. speed</v-col>
              </v-row>
              <div class="strategy-scroll">
                <v-row v-for="(row, index) in stretegies" :key="`suggestion-${index}`" class="data-row">
                  <v-col>{{ index | PlusOnePadZero }}</v-col>
                  <v-col>{{ row.grade_list }}<small>%</small></v-col>
                  <v-col>{{ row.distance_list | MtoKm | RoundToTwo }}<small>km</small></v-col>
                  <v-col>
                    <EditInputColumn @updateValue="updateObject" :original="row" :index="index" :editing="editing"/>
                  </v-col>
                  <v-col>{{ row.suggestion_speed }}<small>kph</small></v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="tool-area">
        <div v-if="editing">
          <div class="btn-round">
             <img :src="require('@/assets/images/re.png')" @click="restore" class="btn" alt="save change">
          </div>
          <div class="btn-round">
               <img :src="require('@/assets/images/radix-icons_cross-2.png')" @click="cancel" class="btn" alt="save change">
            </div>
          <div class="btn-round">
              <img :src="require('@/assets/images/iconamoon_check.png')" @click="save" class="btn" alt="save change">
          </div>
        </div>
        <div v-else class="btn-round">
           <img :src="require('@/assets/images/material-symbols_edit.png')" @click="editMode" class="btn" alt="edit data">
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { updateSuggestion } from '@/services/api/analyse'
import StrategyChart from '@/components/ridingStrategy/StrategyChart'
import EditInputColumn from '@/components/ridingStrategy/EditInputColumn'
export default {
  name: 'StrategyBlock',
  components: { StrategyChart, EditInputColumn },
  props: {
    data: {
      type: Object,
      default: () => ({
        predict_route_id: '',
        img_path_info: '',
        riding_data: {
          distance: 17014.83,
          duration: 10541.74,
          elevation_gain: 111.6,
          average_power: 216.14221133923527,
          max_power: 752,
          energy_output: 1147.499,
          TSS: 'None',
          calories: 274075.546154,
          average_tuili: 2.401580125991503,
          max_tuili: 13.833333333333334,
          average_speed: 20.214663401770576,
          max_speed: 65.535,
          average_heart_rate: 'None',
          max_heart_rate: 'None',
          average_cadence: 'None',
          max_cadence: 'None'
        },
        strategy: [],
        route_info: []
      })
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      editing: false,
      localData: {},
      strategyUpdating: false
    }
  },
  watch: {
    data: {
      handler (val) {
        // console.log('[strategyBlock]watch data', val)
        this.localData = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('auth', ['profileGetter', 'FTPGetter', 'levelGetter']),
    stretegies () {
      return this.localData.strategy
    },
    routeInfo () {
      return this.localData.route_info
    }
  },
  mounted () {
    this.localData = JSON.parse(JSON.stringify(this.data))
  },
  methods: {
    editMode () {
      this.editing = true
    },
    restore () {
      this.localData.strategy = JSON.parse(JSON.stringify(this.data.strategy))
    },
    cancel () {
      this.editing = false
      this.localData.strategy = JSON.parse(JSON.stringify(this.data.strategy))
    },
    async save () {
      this.editing = false
      this.strategyUpdating = true
      const newStrategy = []
      for (let i = 0; i < this.localData.strategy.length; i++) {
        const row = this.localData.strategy[i]
        newStrategy.push({
          elevation_list: row.elevation_list,
          grade_list: row.grade_list,
          distance_list: row.distance_list,
          suggestion_power_original: this.data.strategy[i].suggestion_power,
          suggestion_power_revised: row.suggestion_power
        })
      }
      const formData = {
        member_gender: this.profileGetter.gender,
        member_weight: this.profileGetter.weight,
        bike_weight: this.profileGetter.bike_weight,
        wind_speed: this.profileGetter.wind_speed,
        temperature: this.profileGetter.temperature,
        member_level: this.levelGetter,
        FTP: this.FTPGetter,
        strategy: newStrategy
      }
      try {
        const { success, data } = await updateSuggestion(formData)
        this.strategyUpdating = false
        if (success) {
          if (data.response_code === 200) {
            this.percentage = 100
            this.$emit('updateStrategy', this.index, data.response_data)
          } else {
            // this.resetAction(data.response_msg)
          }
        } else {
          // this.resetAction('network fail!')
          // this.errorMsg = 'network fail!'
        }
      } catch (e) {
        let message = ''
        if (typeof e.response.data === 'undefined') {
          message = 'Cors issue, please check backend service'
        } else {
          message = e.message
        }
        console.log('error', message)
        this.strategyUpdating = false
        alert('analysis fail!, correct strategy power value and try again')
        // this.resetAction(message)
        // this.errorMsg = message
      }
    },
    updateObject (index, item) {
      this.localData.strategy[index] = item
      // console.log('[StrategyBlock]updateObject', index, item)
      // console.log('updateObject', this.localData.strategy[0].suggestion_power, this.data.strategy[0].suggestion_power)
    }
  }
}
</script>
<style lang="scss" scoped>
.riding-data-card{
  margin-bottom: 5px;
  .row{
    background-color: #e7e7e7;
    &:nth-child(odd){
      background-color: #f4f4f4;
    }
  }
  label{
    font-weight: bold;
    color:#898989;
  }
  .value{
    font-weight: bold;
    color:#000;
    font-size: 24px;
    small{
      font-size: 12px;
      margin-left:5px;
    }
  }
}
.strategy-card{
  &.updating{
    opacity: 0.3;
  }
  .v-card__title{
    background-color: #5f5f5f;
    color: #fff;
    padding: 10px 20px;
  }
  .strategy-scroll{
    height:508px;
    overflow-y: scroll;
    position:relative;
    margin: 12px -12px -12px;
    .row{
      margin:0;
    }
  }
  .row{
    .col{
      &:first-child{
        padding-left:20px;
      }
      &:last-child{
        padding-right:20px;
      }
    }
  }
  .col{
    padding: 12px 1px;
    letter-spacing: -1px;
    font-size: 14px;
    small{
      font-size: 8px;
      margin-left: 1px;
    }
  }
  .header-row{
    background: #878787;
    color: #fff;
    font-size: 12px;
    padding-right: 18px;
    .col{
      padding: 4px 1px;
    }
  }
  .data-row{
    background-color: #e7e7e7;
    color:#000;
    &:nth-child(odd){
      background-color: #f4f4f4;
    }
  }
}
.tool-area{
  .btn-round{
    width:26px;
    height:26px;
    text-align: center;
    line-height: 26px;
    border-radius: 100%;
    background-color: #878787;
    display:inline-block;
    margin-right:5px;
    cursor: pointer;
    .btn{
      margin-top:3px;
    }
  }
}
</style>
