<template>
  <div>
    <h3 class="mb-3">Activity analysis</h3>
    <v-card class="level-card mb-5">
      <v-container>
        <v-row>
          <div class="summary-item">
            <div class="section-title">Fit file Analyzation</div>
            <div class="cat-title">Fit File Segmentation</div>
            <div class="subject">{{ localSummary.fit.label }}</div>
            <div class="value">
              <animated-number
                :value="localSummary.fit.value"
                :duration="2000"
              />
              <small>parameters</small>
            </div>
          </div>
          <div class="summary-item">
            <div class="section-title">&nbsp;</div>
            <div class="cat-title">Classifier</div>
            <div class="subject">{{ localSummary.classfier.label }}</div>
            <div class="value">
              <animated-number
                :value="localSummary.classfier.value"
                :duration="2000"
              />
              <small>parameters</small>
            </div>
          </div>
          <div class="summary-item">
            <div class="section-title">Route Prediction</div>
            <div class="cat-title">Route Segmentation</div>
            <div class="subject">{{ localSummary.route.label }}</div>
            <div class="value">
              <animated-number
                :value="localSummary.route.value"
                :duration="2000"
              />
              <small>parameters</small>
            </div>
          </div>
          <div class="summary-item">
            <div class="section-title">&nbsp;</div>
            <div class="cat-title">Predictor</div>
            <div class="subject">{{ localSummary.predictor.label }}</div>
            <div class="value">
              <animated-number
                :value="localSummary.predictor.value"
                :duration="2000"
              />
              <small>parameters</small>
            </div>
          </div>
        </v-row>
        <v-row>
          <div class="circle-item">
            <LevelChart :level-value="fiveSecCal.percent" :level-label="fiveSecCal.text" />
            <div class="circle-item-value">
              <div class="value">5”</div>
              <div>Sprint</div>
            </div>
            <div class="circle-item-title" :title="fiveSecCal.percent">{{ fiveSecCal.text }}</div>
          </div>
          <div class="circle-item">
            <LevelChart :level-value="oneMinCal.percent" :level-label="oneMinCal.text" />
            <div class="circle-item-value">
              <div class="value">1’</div>
              <div>Sprint</div>
            </div>
            <div class="circle-item-title" :title="oneMinCal.percent">{{ oneMinCal.text }}</div>
          </div>
          <div class="circle-item">
            <LevelChart :level-value="fiveMinsCal.percent" :level-label="fiveMinsCal.text" />
            <div class="circle-item-value">
              <div class="value">5‘</div>
              <div>Endurance</div>
            </div>
            <div class="circle-item-title" :title="fiveMinsCal.percent">{{ fiveMinsCal.text }}</div>
          </div>
          <div class="circle-item">
            <LevelChart :level-value="ftpCal.percent" :level-label="ftpCal.text" />
            <div class="circle-item-value">
              <div class="value">FTP</div>
              <div>Test</div>
            </div>
            <div class="circle-item-title" :title="ftpCal.percent">{{ ftpCal.text }}</div>
          </div>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="analysation-card">
      <v-card-text>
        <v-container class="pa-0">
          <v-row class="chart-area">
            <StrategyChart :route-data="routeInfoLocal" />
          </v-row>
        <v-row>
          <v-col class="flex-1">
              <label>FTP</label>
              <p class="value">
                {{ analysation.FTP }}
              </p>
              <!-- TODO: add edit input switch -->
            </v-col>
          <v-col class="flex-1">
            <label>Distance</label>
            <p class="value">
              {{ analysation.distance | MtoKm | RoundToTwo | NumberComma }}<small>Km</small>
            </p>
            <!-- TODO: add edit input switch -->
          </v-col>
          <v-col class="flex-2 me-5">
            <label>Start time</label>
            <p class="value">
              {{ analysation.start_time | LocalTime }}
            </p>
          </v-col>
          <v-col class="flex-1">
            <label>Duration</label>
            <p class="value">
              {{ analysation.duration | FormatSeconds }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label>elevation gain</label>
            <p class="value">
              {{ analysation.elevation_gain | RoundToTwo | NumberComma }}<small>m</small>
            </p>
          </v-col>
          <v-col>
            <label>Calories</label>
            <p class="value">
              {{ analysation.calories | RoundToTwo | NumberComma }}<small>Kcal</small>
            </p>
          </v-col>
          <v-col>
            <label>Energy output</label>
            <p class="value">
              {{ analysation.energy_output | RoundToTwo | NumberComma }}<small>kj</small>
            </p>
          </v-col>
          <v-col>
            <label>TSS</label>
            <p class="value">
              {{ analysation.TSS | RoundToTwo | NumberComma }}
            </p>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label>Avg. power</label>
            <p class="value">
              {{ analysation.average_power }}<small>W</small>
            </p>
          </v-col>
          <v-col>
            <label>Avg. w/kg</label>
            <p class="value">
              {{ analysation.average_tuili }}
            </p>
          </v-col>
          <v-col>
            <label>Avg. speed</label>
            <p class="value">
              {{ analysation.average_speed }}<small>kph</small>
            </p>
          </v-col>
          <v-col>
            <label>Avg. cadence</label>
            <p class="value">
              {{ analysation.average_cadence }}<small>rpm</small>
            </p>
          </v-col>
          <v-col>
            <label>Avg. heart rate</label>
            <p class="value">
              {{ analysation.average_heart_rate }}<small>bpm</small>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label>Max power</label>
            <p class="value">
              {{ analysation.max_power }}<small>W</small>
            </p>
          </v-col>
          <v-col>
            <label>Max w/kg</label>
            <p class="value">
              {{ analysation.max_tuili }}
            </p>
          </v-col>
          <v-col>
            <label>Max speed</label>
            <p class="value">
              {{ analysation.max_speed }}<small>kph</small>
            </p>
          </v-col>
          <v-col>
            <label>Max cadence</label>
            <p class="value">
              {{ analysation.max_cadence }}<small>rpm</small>
            </p>
          </v-col>
          <v-col>
            <label>Max heart rate</label>
            <p class="value">
              {{ analysation.max_heart_rate }}<small>bpm</small>
            </p>
          </v-col>
        </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import RoundToTwo from '@/mixins/tools'
import LevelChart from '@/components/ridingStrategy/LevelChart'
import StrategyChart from '@/components/ridingStrategy/StrategyChart'
import AnimatedNumber from '@/components/ridingStrategy/AnimateNumber'
export default {
  components: { StrategyChart, LevelChart, AnimatedNumber },
  name: 'AnalysationBlock',
  props: {
    gender: {
      type: String,
      default: 'male'
    },
    summary: {
      type: Object,
      default: () => ({
        classfier: {
          label: 'LSTM',
          value: 153200
        },
        fit: {
          label: 'LSTM',
          value: 153200
        },
        predictor: {
          label: 'LSTM',
          value: 153200
        },
        route: {
          label: 'LSTM',
          value: 153200
        }
      })
    },
    memberLevel: {
      type: Object,
      default: () => ({
        five_sec_cla: 4.0,
        one_min_cla: 8.0,
        five_min_cla: 5.0,
        FTP_level: 6.0
      })
    },
    routeInfo: {
      type: Array,
      default: () => ([])
    },
    analysation: {
      type: Object,
      default: () => ({
        distance: 0.0,
        duration: 0.0,
        elevation_gain: 0.0,
        average_power: 0.0,
        max_power: 0,
        energy_output: 0.0,
        TSS: 0.0,
        calories: 0.0,
        average_tuili: 0.0,
        max_tuili: 0.0,
        average_speed: 0.0,
        max_speed: 0.0,
        average_heart_rate: 0.0,
        max_heart_rate: 0,
        average_cadence: 0.0,
        max_cadence: 0,
        FTP: 0.0
      })
    }
  },
  data () {
    return {
      levelParams: {
        male: {
          five_sec_cla: [
            {
              min: 0,
              max: 4,
              text: 'Very Weak'
            },
            {
              min: 4,
              max: 8.2,
              text: 'Weak'
            },
            {
              min: 8.2,
              max: 10.7,
              text: 'Novice 1'
            },
            {
              min: 10.7,
              max: 12.5,
              text: 'Novice 2'
            },
            {
              min: 12.5,
              max: 14.3,
              text: 'Fair'
            },
            {
              min: 14.3,
              max: 16.1,
              text: 'Moderate'
            },
            {
              min: 16.1,
              max: 17.9,
              text: 'Good'
            },
            {
              min: 17.9,
              max: 19.7,
              text: 'Very Good'
            },
            {
              min: 19.7,
              max: 21.5,
              text: 'Excellent'
            },
            {
              min: 21.5,
              max: 23.4,
              text: 'Exceptional'
            },
            {
              min: 23.4,
              max: 25.2,
              text: 'World Class'
            }
          ],
          one_min_cla: [
            {
              min: 0,
              max: 3.2,
              text: 'Very Weak'
            },
            {
              min: 3.2,
              max: 4.9,
              text: 'Weak'
            },
            {
              min: 4.9,
              max: 5.9,
              text: 'Novice 1'
            },
            {
              min: 5.9,
              max: 6.6,
              text: 'Novice 2'
            },
            {
              min: 6.6,
              max: 7.3,
              text: 'Fair'
            },
            {
              min: 7.3,
              max: 8,
              text: 'Moderate'
            },
            {
              min: 8,
              max: 8.7,
              text: 'Good'
            },
            {
              min: 8.7,
              max: 9.4,
              text: 'Very Good'
            },
            {
              min: 9.4,
              max: 10.1,
              text: 'Excellent'
            },
            {
              min: 10.1,
              max: 10.8,
              text: 'Exceptional'
            },
            {
              min: 10.8,
              max: 11.5,
              text: 'World Class'
            }
          ],
          five_min_cla: [
            {
              min: 0,
              max: 1,
              text: 'Very Weak'
            },
            {
              min: 1,
              max: 1.7,
              text: 'Weak'
            },
            {
              min: 1.7,
              max: 2.5,
              text: 'Novice 1'
            },
            {
              min: 2.5,
              max: 3.2,
              text: 'Novice 2'
            },
            {
              min: 3.2,
              max: 3.8,
              text: 'Fair'
            },
            {
              min: 3.8,
              max: 4.4,
              text: 'Moderate'
            },
            {
              min: 4.4,
              max: 5.1,
              text: 'Good'
            },
            {
              min: 5.1,
              max: 5.7,
              text: 'Very Good'
            },
            {
              min: 5.7,
              max: 6.3,
              text: 'Excellent'
            },
            {
              min: 6.3,
              max: 7,
              text: 'Exceptional'
            },
            {
              min: 7,
              max: 7.6,
              text: 'World Class'
            }
          ],
          ftp_cla: [
            {
              min: 0,
              max: 0.7,
              text: 'Very Weak'
            },
            {
              min: 0.7,
              max: 1.3,
              text: 'Weak'
            },
            {
              min: 1.3,
              max: 2,
              text: 'Novice 1'
            },
            {
              min: 2,
              max: 2.6,
              text: 'Novice 2'
            },
            {
              min: 2.6,
              max: 3.2,
              text: 'Fair'
            },
            {
              min: 3.2,
              max: 3.7,
              text: 'Moderate'
            },
            {
              min: 3.7,
              max: 4.3,
              text: 'Good'
            },
            {
              min: 4.3,
              max: 4.9,
              text: 'Very Good'
            },
            {
              min: 4.9,
              max: 5.5,
              text: 'Excellent'
            },
            {
              min: 5.5,
              max: 6,
              text: 'Exceptional'
            },
            {
              min: 6,
              max: 6.6,
              text: 'World Class'
            }
          ]
        },
        female: {
          five_sec_cla: [
            {
              min: 0,
              max: 3.7,
              text: 'Very Weak'
            },
            {
              min: 3.8,
              max: 7.0,
              text: 'Weak'
            },
            {
              min: 7.1,
              max: 8.7,
              text: 'Novice 1'
            },
            {
              min: 8.8,
              max: 10.1,
              text: 'Novice 2'
            },
            {
              min: 10.2,
              max: 11.4,
              text: 'Fair'
            },
            {
              min: 11.5,
              max: 12.7,
              text: 'Moderate'
            },
            {
              min: 12.8,
              max: 14.0,
              text: 'Good'
            },
            {
              min: 14.1,
              max: 15.4,
              text: 'Very Good'
            },
            {
              min: 15.5,
              max: 16.7,
              text: 'Excellent'
            },
            {
              min: 16.8,
              max: 18.0,
              text: 'Exceptional'
            },
            {
              min: 18.1,
              max: 19.4,
              text: 'World Class'
            }
          ],
          one_min_cla: [
            {
              min: 0,
              max: 2.9,
              text: 'Very Weak'
            },
            {
              min: 3,
              max: 4.0,
              text: 'Weak'
            },
            {
              min: 4.1,
              max: 4.7,
              text: 'Novice 1'
            },
            {
              min: 4.8,
              max: 5.3,
              text: 'Novice 2'
            },
            {
              min: 5.4,
              max: 5.9,
              text: 'Fair'
            },
            {
              min: 6,
              max: 6.4,
              text: 'Moderate'
            },
            {
              min: 6.5,
              max: 7,
              text: 'Good'
            },
            {
              min: 7.1,
              max: 7.5,
              text: 'Very Good'
            },
            {
              min: 7.6,
              max: 8.1,
              text: 'Excellent'
            },
            {
              min: 8.2,
              max: 8.6,
              text: 'Exceptional'
            },
            {
              min: 8.7,
              max: 9.3,
              text: 'World Class'
            }
          ],
          five_min_cla: [
            {
              min: 0,
              max: 0.6,
              text: 'Very Weak'
            },
            {
              min: 0.7,
              max: 1.2,
              text: 'Weak'
            },
            {
              min: 1.3,
              max: 2.0,
              text: 'Novice 1'
            },
            {
              min: 2.1,
              max: 2.5,
              text: 'Novice 2'
            },
            {
              min: 2.6,
              max: 3.1,
              text: 'Fair'
            },
            {
              min: 3.2,
              max: 3.7,
              text: 'Moderate'
            },
            {
              min: 3.8,
              max: 4.3,
              text: 'Good'
            },
            {
              min: 4.4,
              max: 4.9,
              text: 'Very Good'
            },
            {
              min: 5,
              max: 5.5,
              text: 'Excellent'
            },
            {
              min: 5.6,
              max: 6.1,
              text: 'Exceptional'
            },
            {
              min: 6.2,
              max: 6.7,
              text: 'World Class'
            }
          ],
          ftp_cla: [
            {
              min: 0,
              max: 0.4,
              text: 'Very Weak'
            },
            {
              min: 0.5,
              max: 0.9,
              text: 'Weak'
            },
            {
              min: 1,
              max: 1.6,
              text: 'Novice 1'
            },
            {
              min: 1.7,
              max: 2,
              text: 'Novice 2'
            },
            {
              min: 2.1,
              max: 2.6,
              text: 'Fair'
            },
            {
              min: 2.7,
              max: 3.1,
              text: 'Moderate'
            },
            {
              min: 3.2,
              max: 3.6,
              text: 'Good'
            },
            {
              min: 3.7,
              max: 4.1,
              text: 'Very Good'
            },
            {
              min: 4.2,
              max: 4.6,
              text: 'Excellent'
            },
            {
              min: 4.7,
              max: 5.1,
              text: 'Exceptional'
            },
            {
              min: 5.2,
              max: 5.7,
              text: 'World Class'
            }
          ]
        }
      }
    }
  },
  mixins: [
    RoundToTwo
  ],
  computed: {
    localSummary () {
      // console.log('summary', this.summary)
      return this.summary
    },
    routeInfoLocal () {
      return this.routeInfo
    },
    fiveSecCal () {
      const value = {
        text: 'no match',
        percent: 0
      }
      this.levelParams[this.gender].five_sec_cla.forEach(item => {
        if (this.memberLevel.five_sec_cla >= item.min && (this.memberLevel.five_sec_cla < item.max || item.max === 25.2)) {
          value.text = item.text
          value.percent = this.RoundToTwo((this.memberLevel.five_sec_cla / item.max) * 100)
        }
      })
      return value
    },
    oneMinCal () {
      const value = {
        text: 'no match',
        percent: 0
      }
      this.levelParams[this.gender].one_min_cla.forEach(item => {
        if (this.memberLevel.one_min_cla >= item.min && (this.memberLevel.one_min_cla < item.max || item.max === 11.5)) {
          value.text = item.text
          value.percent = this.RoundToTwo((this.memberLevel.one_min_cla / item.max) * 100)
        }
      })
      return value
    },
    fiveMinsCal () {
      const value = {
        text: 'no match',
        percent: 0
      }
      this.levelParams[this.gender].five_min_cla.forEach(item => {
        if (this.memberLevel.five_min_cla >= item.min && (this.memberLevel.five_min_cla < item.max || item.max === 7.6)) {
          value.text = item.text
          value.percent = this.RoundToTwo((this.memberLevel.five_min_cla / item.max) * 100)
        }
      })
      return value
    },
    ftpCal () {
      const value = {
        text: 'no match',
        percent: 0
      }
      this.levelParams[this.gender].ftp_cla.forEach(item => {
        if (this.memberLevel.FTP_level >= item.min && (this.memberLevel.FTP_level <= item.max || item.max === 6.6)) {
          value.text = item.text
          value.percent = this.RoundToTwo((this.memberLevel.FTP_level / item.max) * 100)
          // console.log('ftp', value.percent, (this.memberLevel.FTP_level / item.max) * 100)
        }
      })
      return value
    }
  },
  methods: {
    formatComma (value) {
      return Number(value).toFixed(0) // .toLocaleString('en-US')
    }
  }
}
</script>
<style lang="scss" scoped>
h3{
  font-size: 30px;
}
.chart-area{
  height:230px;
}
.level-card{
  display:flex;
  margin-bottom: 0px;
  .row{
    background-color: #f4f4f4;
    overflow: hidden;
    &:nth-child(odd){
      background-color: #e7e7e7;
    }
    .summary-item{
      flex:1;
      padding-bottom: 20px;
      &:first-child{
        padding-left:48px;
      }
      &:last-child{
        padding-right:48px;
      }
      .section-title{
        font-size: 20px;
        margin-bottom: 10px;
      }
      .cat-title{
        font-size: 14px;
        color:#888888;
      }
      .subject{
        font-size: 19px;
        letter-spacing:-1px;
        font-weight: bold;
      }
      .value{
        font-size: 24px;
        small{
          font-size: 12px;
        }
      }
    }
  }
  .circle-item{
    position: relative;
    flex:1;
    padding-top: 15px;
    padding-bottom: 20px;
    .circle-item-value{
      width:120px;
      height:122px;
      margin:0 auto;
      //background-image: url('~@/assets/images/level_circle.png');
      text-align: center;
      padding-top:36px;
      div{
        color:#8d8d8d;
      }
      .value{
        color:#888888;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
      }
    }
    .circle-item-title{
      text-align: center;
      font-weight: bold;
    }
    .chart-wrapper{
      position: absolute;
      top: -40px;
      left: calc(50% - 60px);
      transform: scale(-1, 1);
      width: 120px;
    }
  }
}
.analysation-card{
  .row{
    background-color: #e7e7e7;
    &:nth-child(odd){
      background-color: #f4f4f4;
    }
  }
  label{
    font-weight: bold;
    color:#898989;
  }
  .value{
    font-weight: bold;
    color:#000;
    font-size: 24px;
    small{
      font-size: 12px;
      margin-left:5px;
    }
  }
}
.flex-1{
  flex:1;
}
.flex-2{
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 0%;
}

</style>
