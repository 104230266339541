import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '@/views/NotFound.vue'
import HomeView from '@/views/HomeView'
import Login from '@/views/auth/LoginView'
import RidingStrategy from '@/views/RidingStrategyView'

Vue.use(VueRouter)

/*
預計：
index before signin
signin
signup
forget password
reset password
 - finish stage
 - fail stage
email verify success /fail
riding strategy (upload)
strategy history

*/
const routes = [
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'default',
      requiresAuth: false
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'default',
      requiresAuth: false
    }
  },
  {
    path: '/riding-strategy',
    name: 'ridingStrategy',
    meta: {
      layout: 'authrized',
      requiresAuth: false // TODO: change to true with login
    },
    component: RidingStrategy
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// TODO: add auth check, logout, store...
export default router
