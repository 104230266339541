export default {
  methods: {
    makeKm (value) {
      if (!(parseInt(value) || parseFloat(value))) return 0
      return +(Math.round(value / 1000 + 'e+2') + 'e-2')
    },
    RoundToTwo (value) {
      if (!(parseInt(value) || parseFloat(value))) return 0
      return +(Math.round(value + 'e+2') + 'e-2')
    }
  }
}
