<template>
  <div class="chart-wrapper">
    <doughnut-chart :data="chartData" :options="options" :width="width" :height="height" />
  </div>
</template>
<script>
import DoughnutChart from '@/components/ridingStrategy/DoughnutChart'
export default {
  name: 'LevelChart',
  components: { DoughnutChart },
  props: {
    levelValue: {
      type: Number,
      default: () => 0
    },
    levelLabel: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      width: 60,
      height: 60,
      chartData: {
        labels: ['', ''],
        datasets: [0, 100]
      },
      options: {
        plugins: {
          legend: false
        },
        responsive: true,
        maintainAspectRatio: false,
        rotation: 0,
        cutout: '90%',
        tooltips: {
          enabled: false
        },
        events: [],
        elements: {
          arc: {
            spacing: 0,
            borderRadius: 30,
            borderWidth: 0
          }
        }
      }
    }
  },
  watch: {
    levelValue: {
      handler: function (val, oldVal) {
        this.fillData()
      },
      deep: true
    }
  },
  methods: {
    fillData () {
      this.chartData = {
        labels: [' ', this.levelLabel],
        datasets: [{
          data: [100 - this.levelValue, this.levelValue],
          backgroundColor: ['#999', 'red'],
          borderColor: ['#f4f4f4', 'red'],
          borderWidth: [1, 7]
        }]
      }
    }
  },
  created () {
  },
  mounted () {
    this.fillData()
  }
}
</script>
<style lang="scss" scoped>
.chart-wrapper {
  width: 100%;
  height: 230px;
  & > div{
    height: 230px;
  }
}
</style>
