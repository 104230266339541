<template>
  <component :is="currentLayout" />
</template>

<script>
import Default from '@/layouts/LayoutDefault'
import Authrized from '@/layouts/LayoutAuthrized'
export default {
  name: 'App',
  data () {
    return {
      layouts: {
        authrized: Authrized,
        default: Default
      }
    }
  },
  computed: {
    currentLayout () {
      if (Object.keys(this.$route.meta).includes('layout')) {
        return this.layouts[this.$route.meta.layout]
      }
      return Default
    }
  }
}
</script>
