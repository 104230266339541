import _axios from 'axios'
import getEnv from '@/utils/env'

const CLIENT_API_URL = `${getEnv('VUE_APP_BASE_URL')}/api/v1/`

export class API {
  constructor (uri) {
    this._injectServerHeaderAuthorization = this._injectServerHeaderAuthorization.bind(this)
    this._injectServerErrorNotification = this._injectServerErrorNotification.bind(this)
    this._injectClientErrorNotification = this._injectClientErrorNotification.bind(this)
    this._injectRedirectErrorNotification = this._injectRedirectErrorNotification.bind(this)
    this._commitBeforeRequestMutation = this._commitBeforeRequestMutation.bind(this)
    this._commitRequestErrorMutation = this._commitRequestErrorMutation.bind(this)
    this._commitAfterResponseMutation = this._commitAfterResponseMutation.bind(this)
    this._commitResponseErrorMutation = this._commitResponseErrorMutation.bind(this)
    const axios = _axios.create({
      baseURL: uri,
      responseType: 'json',
      timeout: 30000
    })

    axios.interceptors.request.use(
      this._commitBeforeRequestMutation,
      this._commitRequestErrorMutation
    )
    axios.interceptors.response.use(
      this._commitAfterResponseMutation,
      this._commitResponseErrorMutation
    )

    this.axios = axios
  }

  _injectServerHeaderAuthorization (config) {
    const token = ''

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }

  _injectServerErrorNotification (
    error,
    {
      status,
      statusText,
      data,
      message,
      method,
      responseType,
      headers,
      url
    }
  ) {
    // 處理錯誤訊息
    return error
  }

  _injectClientErrorNotification (
    error,
    {
      status,
      statusText,
      data,
      message,
      method,
      responseType,
      headers,
      url
    }
  ) {
    // 處理錯誤訊息
    return error
  }

  _injectRedirectErrorNotification (
    error,
    {
      status,
      statusText,
      data,
      message,
      method,
      responseType,
      headers,
      url
    }
  ) {
    // 處理錯誤訊息
    return error
  }

  // 發出 request 之前
  _commitBeforeRequestMutation (config) {
    const newConfig = this._injectServerHeaderAuthorization(config)
    return newConfig
  }

  // 發出 request 之後，但沒有 response 的錯誤
  _commitRequestErrorMutation (error) {
    return error
  }

  // 收到 response 之後
  _commitAfterResponseMutation (response) {
    return response
  }

  // 收到 response 之後，但不是 200 oK
  _commitResponseErrorMutation (error) {
    const { response, message, config } = error
    const { status, statusText, data } = response
    const { method, responseType, headers, url } = config
    const points = {
      status,
      statusText,
      data,
      message,
      method,
      responseType,
      headers,
      url
    }
    if ((status >= 500) & (status < 600)) {
      // * ServerErrors
      this._injectServerErrorNotification(error, points)
    } else if ((status >= 400) & (status < 500)) {
      // * ClientErrors
      this._injectClientErrorNotification(error, points)
      return error
    } else if ((status >= 300) & (status < 400)) {
      // * Redirects
      this._injectRedirectErrorNotification(error, points)
    } else {
      this._injectRedirectErrorNotification(error, points)
    }
    return error
  }
}

export default new API(CLIENT_API_URL).axios
