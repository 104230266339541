<template>
  <div class="chart-wrapper">
    <line-chart :data="chartData" :options="options" />
  </div>
</template>
<script>
import makeKm from '@/mixins/tools'
import LineChart from '@/components/ridingStrategy/LineChart'
export default {
  name: 'StrategyChart',
  components: { LineChart },
  props: {
    routeData: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      chartData: {},
      options: {
        fill: true,
        borderColor: 'rgba(0,0,0,0.2)',
        pointBorderWidth: 0,
        pointBorderColor: 'rgba(0,0,0,0.2)',
        backgroundColor: 'rgba(0,0,0,0.2)',
        borderWidth: 0.3,
        pointRadius: 0.6,
        hoverBackgroundColor: 'rgba(0,0,0,0.6)',
        plugins: {
          legend: false,
          tooltip: {
            displayColors: false,
            enabled: true,
            callbacks: {
              title: (tooltipItems) => {
                return 'Distance: ' + tooltipItems[0].label
              },
              label: (tooltipItems) => {
                return 'Elevation: ' + tooltipItems.raw + 'm'
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              maxTicksLimit: 6,
              callback: function (value, index, ticks) {
                return value + 'm'
              }
            }
          },
          x: {
            ticks: {
              maxTicksLimit: 10
            }
          }
        }

      }
    }
  },
  mixins: [
    makeKm
  ],
  watch: {
    routeData: {
      handler: function (val, oldVal) {
        this.fillData()
      },
      deep: true
    }
  },
  methods: {
    fillData () {
      const labels = []
      const data = []
      const labelsOptimized = []
      const dataOptimized = []
      const minDist = 0.01 // 5m
      const minHeight = 1 // ~10m
      if (this.routeData) {
        this.routeData.forEach((row) => {
          labels.push(this.makeKm(row.distance) + 'km')
          data.push(row.elevation)
        })
        // 重新取樣距離與高度的範疇，讓橫軸的每個點至少有固定距離
        labels.forEach((dist, index) => {
          if (index === 0 || index === labels.length - 1 ||
            (dist - labelsOptimized[labelsOptimized.length - 1]) > minDist ||
            Math.abs(data[index] - dataOptimized[dataOptimized.length - 1]) > minHeight) {
            labelsOptimized.push(dist)
            dataOptimized.push(data[index])
          }
        })
      }
      // console.log('labelsOptimized', labelsOptimized)
      // console.log('dataOptimized', dataOptimized)
      this.chartData = {
        labels: labelsOptimized,
        datasets: [{
          data: dataOptimized
        }]
      }
    }
  },
  created () {
  },
  mounted () {
    this.fillData()
    // console.log('routeData', this.routeData)
  }
}
</script>
<style lang="scss" scoped>
.chart-wrapper {
  width: 100%;
  height: 230px;
  & > div{
    height: 230px;
  }
}
</style>
