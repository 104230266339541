<template>
  <div class="suggestion-column" :class="editing ? 'editing': ''">
    <div v-if="editing">
      <!-- input + original value -->
      <span class="original-value">{{ original_power }}</span>
      <input type="text" :class="(original_power !== suggestion_power)? 'changed': ''" name="suggestion_power" v-model="suggestion_power">
    </div>
    <div v-else>
      {{ display_power }}
      <small>W</small>
    </div>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex'
export default {
  name: 'EditInputColumn',
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number
    },
    original: {
      type: Object,
      default: () => ({
        grade_list: 0,
        distance_list: 0,
        suggestion_power: 0,
        suggestion_speed: 0,
        elevation_list: 0
      })
    }
  },
  data () {
    return {
      init: true,
      original_suggestion_power: '',
      // suggestion_power: '',
      localData: {
        grade_list: 0,
        distance_list: 0,
        suggestion_power: 0,
        suggestion_speed: 0,
        elevation_list: 0
      },
      changed: false
    }
  },
  watch: {
    original: {
      handler: function (val, oldVal) {
        // console.log('[EditInputColumn]watch original')
        if (this.init && oldVal === undefined) {
          this.init = false
        } else if (val) {
          this.localData = JSON.parse(JSON.stringify(val))
        }
      },
      immediate: true
    }
  },
  computed: {
    original_power: function () {
      return this.original.suggestion_power.toString()
    },
    suggestion_power: {
      get: function () {
        return this.localData.suggestion_power.toString()
      },
      set: function (value) {
        this.updateValue(value)
        // console.log('[EditInputColumn]suggestion_power set', value)
      }
    },
    display_power: function () {
      return this.localData.suggestion_power
    }
  },
  mounted () {
    this.original_suggestion_power = this.original.suggestion_power.toString()
    this.localData = JSON.parse(JSON.stringify(this.original))
  },
  methods: {
    updateValue (value) {
      this.localData.suggestion_power = parseFloat(value)
      this.$emit('updateValue', this.index, this.localData)
    }
  }
}
</script>
<style lang="scss" scoped>
  .suggestion-column.editing{
    margin: -12px 0;
  }
  .original-value{
    font-size: 10px;
    color: #888888;
    line-height: 12px;
  }
  input{
    margin-top:-3px;
    width: 100%;
    height: 22px;
    line-height: 22px;
    border-bottom: 2px solid #000;
    &:focus{
      outline: none;
    }
    &.changed{
      color:#f54b4b;
    }
  }
</style>
