import Vue from 'vue'
import Moment from 'moment'

Vue.filter('RoundToTwo', value => {
  if (!(parseInt(value) || parseFloat(value))) return ' - '
  return +(Math.round(value + 'e+2') + 'e-2')
})

Vue.filter('NumberComma', value => {
  if (!(parseInt(value) || parseFloat(value))) return ' - '
  return value.toLocaleString('en-US')
})

Vue.filter('PlusOnePadZero', value => {
  value = value + 1
  return value.toString().padStart(2, '0')
})

Vue.filter('LocalTime', value => {
  if (!value) return ' - '
  return Moment(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('ShortFilename', value => {
  if (value.length > 40) {
    return value.substring(0, 16) + ' ... ' + value.substring(value.length - 16, value.length)
  }
  return value
})

Vue.filter('MtoKm', value => {
  return value / 1000
})

Vue.filter('FormatSeconds', value => {
  return Moment.utc(value * 1000).format('HH:mm:ss')
})
