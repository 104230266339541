<template>
  <v-container class="ps-0 pd-0 main-content" fluid>
    <v-container class="pa-0 pt-1 content-wrapper">
      <v-row class="top-area" :class="uploaded? 'has-data':''">
        <v-col cols="4" class="pa-0 upload-pannel">
          <UploadBlock @uploadedData="updateUploadData" @uploadingFile="uploadingFixFile"/>
        </v-col>
        <v-col v-if="uploaded" cols="8" :class="uploadingFix ? 'uploading' : ''" class="pa-0 info-pannel">
          <ProfileBlock @uploadedData="updateUploadData" @uploadingFile="uploadingFixFile" />
          <AnalysationBlock :analysation="analysation" :route-info="route_info" :member-level="memberLevel" :summary="analysisSummary" :gender="profile.gender" />
        </v-col>
      </v-row>
      <v-row v-if="uploaded" class="down-area mb-5">
        <v-col cols="12" class="pa-0">
          <h2 class="title mb-5">Performance Strategy</h2>
          <p class="note-text">The suggestion is derived from the AI analysis of the FIT file.</p>
        </v-col>
        <v-col v-for="(data, index) in fitStrategies" :key="`strategy-block-${index}`" :class="uploadingFix ? 'uploading' : ''" cols="12" class="pa-0 strategey-blocks">
          <StrategyBlock :data="data" :index="index" @updateStrategy="updateStrategy" />
        </v-col>
        <v-col v-if="gpxStrategy" cols="12" :class="uploadingGpx? 'uploading': ''" class="pa-0 strategey-blocks">
          <StrategyBlock :data="gpxStrategy" :index="3"  @updateStrategy="updateStrategy" />
        </v-col>
      </v-row>
      <v-row v-if="uploaded" class="gpx-area mb-14">
        <v-col cols="4" class="pa-0 ">
          <GpxUploadBlock @uploadedData="updateUploadGpxData" @uploadingFile="uploadingGpxFile" :member-level="memberLevel"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex'
import UploadBlock from '@/components/ridingStrategy/UploadBlock'
import GpxUploadBlock from '@/components/ridingStrategy/GpxUploadBlock'
import ProfileBlock from '@/components/ridingStrategy/ProfileBlock'
import AnalysationBlock from '@/components/ridingStrategy/AnalysationBlock'
import StrategyBlock from '@/components/ridingStrategy/StrategyBlock'
export default {
  components: { UploadBlock, ProfileBlock, AnalysationBlock, StrategyBlock, GpxUploadBlock },
  name: 'RidingStrategyView',
  data () {
    return {
      init: true,
      uploaded: false,
      fitTitle: 'Build your own AI Model',
      gpxTitle: 'Analysis of another route',
      uploadingFix: false,
      uploadingGpx: false,
      profile: {
        gender: 'male',
        FTP: 100,
        age: 28,
        height: 165,
        weight: 58,
        bike_weight: 32,
        wind_speed: 1,
        temperature: 28
      },
      analysisSummary: {
        classfier: {
          label: 'LSTM',
          value: 153200
        },
        fit: {
          label: 'LSTM',
          value: 153200
        },
        predictor: {
          label: 'LSTM',
          value: 153200
        },
        route: {
          label: 'LSTM',
          value: 153200
        }
      },
      memberLevel: {
        five_sec_cla: 4.0,
        one_min_cla: 8.0,
        five_min_cla: 5.0,
        x: 6.0
      },
      analysation: {
        distance: 1.0,
        start_time: '2021-01-01 12:00:00',
        duration: 0.0,
        elevation_gain: 0.0,
        average_power: 0.0,
        max_power: 0,
        energy_output: 0.0,
        TSS: 0.0,
        calories: 0.0,
        average_tuili: 0.0,
        max_tuili: 0.0,
        average_speed: 0.0,
        max_speed: 0.0,
        average_heart_rate: 0.0,
        max_heart_rate: 0,
        average_cadence: 0.0,
        max_cadence: 0,
        FTP: 0.0
      },
      route_info: [],
      strategies: [
        {
          predict_route_id: 'Alpe-dHuez',
          riding_data: {
            distance: 17014.83,
            duration: 10541.74,
            elevation_gain: 111.6,
            average_power: 216.14221133923527,
            max_power: 752,
            energy_output: 1147.499,
            TSS: 'None',
            calories: 274075.546154,
            average_tuili: 2.401580125991503,
            max_tuili: 13.833333333333334,
            average_speed: 20.214663401770576,
            max_speed: 65.535,
            average_heart_rate: 'None',
            max_heart_rate: 'None',
            average_cadence: 'None',
            max_cadence: 'None'
          },
          performance_strategy: [
            {
              grade_list: 8.53,
              distance_list: 376.32,
              suggestion_power: 136.4,
              suggestion_speed: 5.3,
              elevation_list: 735.109
            },
            {
              grade_list: 10.5,
              distance_list: 369.28,
              suggestion_power: 136.4133,
              suggestion_speed: 4.1,
              elevation_list: 773.719
            },
            {
              grade_list: 8.53,
              distance_list: 376.32,
              suggestion_power: 136.4,
              suggestion_speed: 5.3,
              elevation_list: 735.109
            }
          ]
        },
        {
          predict_route_id: '2023-TDF-Stage8',
          riding_data: {
            distance: 247351.1,
            duration: 48557.35,
            elevation_gain: 421.5,
            average_power: 103.51,
            max_power: 752,
            energy_output: 1147.499,
            TSS: 'None',
            calories: 274075.546154,
            average_tuili: 2.401580125991503,
            max_tuili: 13.833333333333334,
            average_speed: 20.214663401770576,
            max_speed: 65.535,
            average_heart_rate: 'None',
            max_heart_rate: 'None',
            average_cadence: 'None',
            max_cadence: 'None'
          },
          performance_strategy: [
            {
              grade_list: 8.53,
              distance_list: 376.32,
              suggestion_power: 136.4,
              suggestion_speed: 5.3,
              elevation_list: 735.109
            },
            {
              grade_list: 10.5,
              distance_list: 369.28,
              suggestion_power: 136.4133,
              suggestion_speed: 4.1,
              elevation_list: 773.719
            },
            {
              grade_list: 8.53,
              distance_list: 376.32,
              suggestion_power: 136.4,
              suggestion_speed: 5.3,
              elevation_list: 735.109
            }
          ]
        }
      ],
      localGpxStrategy: {
      }
    }
  },
  created () {
    // profile to store
  },
  computed: {
    fitStrategies: function () {
      return this.strategies
    },
    gpxStrategy: function () {
      return this.localGpxStrategy
    }
  },
  methods: {
    ...mapActions('auth', ['setFTP', 'setMemberLevel']),
    uploadingFixFile (status) {
      this.uploadingFix = status
    },
    updateUploadData (data) {
      this.uploaded = true
      this.analysisSummary = data.analysis_summary
      this.memberLevel = data.member_level
      this.setMemberLevel(data.member_level)
      this.analysation = data.analysation
      this.setFTP(data.analysation.FTP)
      this.route_info = data.route_info
      this.strategies = data.performance_strategy
      this.uploadingFix = false
    },
    uploadingGpxFile (status) {
      this.uploadingGpx = status
    },
    updateUploadGpxData (data) {
      this.localGpxStrategy = data
      this.uploadingGpx = false
    },
    updateStrategy (index, data) {
      if (index === 3) {
        const newData = JSON.parse(JSON.stringify(this.gpxStrategy))
        newData.riding_data = data.new_analysation
        newData.riding_data.elevation_gain = this.gpxStrategy.riding_data.elevation_gain
        newData.strategy = data.new_strategy
        this.localGpxStrategy = newData
      } else {
        const newData = JSON.parse(JSON.stringify(this.strategies[index]))
        newData.riding_data = data.new_analysation
        newData.riding_data.elevation_gain = this.strategies[index].riding_data.elevation_gain
        newData.strategy = data.new_strategy
        const tempStrategy = JSON.parse(JSON.stringify(this.strategies))
        tempStrategy[index] = newData
        this.strategies = tempStrategy
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main-content{
  padding-top:50px;
}
.page-area{
  height: 100%;
  width: 100%;
  position: relative;
}
.top-area{
  min-height: 500px;
  background-image: url('~@/assets/images/ride_bg.png');
  background-repeat: no-repeat;
  background-position: bottom 0 right 0;
  margin-bottom: -12px;
  &.has-data{
    background-image:none;
    margin-bottom: 40px;
  }
}
.down-area{
  h3{
    font-size: 30px;
  }
  h2.title{
    font-size: 48px!important;
  }
  .note-text{
    font-size: 18px;
    color:#888888;
  }
}

.info-pannel.uploading{
  opacity: 0.4;
}
.strategey-blocks{
  margin-bottom: 30px;
}
.strategey-blocks.uploading{
  opacity: 0.4;
}
</style>
