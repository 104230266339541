<template>
  <v-app>
    <v-main>
      <v-container class="pa-0" fluid fill-height>
        <BannerHeader />
        <router-view />
        <Footer />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import BannerHeader from '@/components/layouts/authrized/BannerHeader'
import Footer from '@/components/layouts/authrized/Footer'
export default {
  components: { BannerHeader, Footer },
  name: 'LayoutAuthrized'
}
</script>
