<template>
  <v-app>
    <v-main>
      <v-container class="d-flex justify-center align-center pa-0" fluid fill-height>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

export default {
  name: 'LayoutDefault'
}
</script>
