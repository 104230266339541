<template>
  <v-container class="footer" fluid>
    <v-container class="pa-0 content-wrapper">
      <p>2023 WhiizU. All rights reserved. </p>
      <p>Upgrate your cycling training by the latest generative AI service.</p>
    </v-container>
  </v-container>
</template>
<script>

export default {
  name: 'BannerHeader'
}
</script>
<style lang="scss" scoped>
.footer {
  height: 100px;
  width: 100%;
  position: relative;
  background-color: #3e3e3e;
  padding-top: 30px;
  color:#fff;
  text-align: center;
  p{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
</style>
