<template>
  <div class="upload-wrapper">
    <h3>Build your own AI Model</h3>
    <p class="note-text">Only support "ROUTE CYCLING" files for analysis.</p>
    <div class="upload-button">
      <div v-if="!file">
        <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
          <div class="dropZone-info" @drag="onChange">
            <span class="dropZone-title">
              <img :src="require('@/assets/images/add-filled.png')" alt="upload icon" />
              <span>Upload activity(.fit)</span>
            </span>
          </div>
          <div class="hover-info">
            Only support *.fit format
          </div>
          <input type="file" @change="onChange">
        </div>
      </div>
      <div v-else class="dropZone-uploaded">
        <div class="dropZone-uploaded-info">
          <img :src="require('@/assets/images/file.png')" alt="upload icon" />
          <span class="dropZone-title">{{ file.name | ShortFilename }}</span>
        </div>
        <div class="uploading-status" :class="[uploading ? 'show' : '']">
          <span>{{ processText }}</span>
          <div class="progress-bar">
            <div class="progress-bar-inner" :style="{ width: percentageText }"></div>
          </div>
        </div>
        <div class="start-btn" :class="[uploading ? 'disabled' : '']" @click="sendFile">
          {{ uploadBtnText }}
        </div>
      </div>
      <v-alert
        v-if="errorMsg"
        border="top"
        color="red lighten-2"
        dark
        :style="{ width: '380px' }"
      >
        {{ errorMsg }}
      </v-alert>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { analyseFit } from '@/services/api/analyse'
export default {
  name: 'UploadBlock',
  data () {
    return {
      file: '',
      dragging: false,
      uploading: false,
      percentage: 0,
      percentTimer: null,
      processText: 'uploading...',
      errorMsg: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['profileGetter']),
    extension () {
      return (this.file) ? this.file.name.split('.').pop() : ''
    },
    uploadBtnText () {
      return (this.uploading) ? 'Wait' : 'Start'
    },
    percentageText () {
      return this.percentage + '%'
    }
  },
  methods: {
    onChange (e) {
      this.errorMsg = ''
      // console.log('onChange', e)
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        this.dragging = false
        return
      }
      this.createFile(files[0])
    },
    progressing () {
      const _this = this
      this.percentage = 0
      this.percentTimer = setInterval(() => {
        _this.percentage += 1
        if (_this.percentage >= 97) {
          clearInterval(_this.percentTimer)
        }
      }, 50)
    },
    createFile (file) {
      if (!file.name.match('.fit')) {
        alert('please select .fit file')
        this.dragging = false
        return
      }

      // if (file.size > 5000000) {
      //   alert('please check file size no over 5 MB.')
      //   this.dragging = false;
      //   return;
      // }

      this.file = file
      this.$store.dispatch('auth/setFitFile', file)
      this.$store.dispatch('auth/setFitFileName', file.name)
      // console.log('this.file', this.file)
      this.dragging = false
    },
    removeFile () {
      this.file = ''
    },
    resetAction (msg) {
      clearInterval(this.percentTimer)
      this.uploading = false
      this.file = ''
      this.processText = msg
    },
    async sendFile () {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('member_gender', this.profileGetter.gender)
      formData.append('member_height', this.profileGetter.height)
      formData.append('member_weight', this.profileGetter.weight)
      formData.append('bike_weight', this.profileGetter.bike_weight)
      formData.append('wind_speed', this.profileGetter.wind_speed)
      formData.append('temperature', this.profileGetter.temperature)
      formData.append('FTP', this.profileGetter.FTP)
      formData.append('uploaded_file_name', this.file.name)
      // console.log('sendFile', formData)
      // start processing
      this.uploading = true
      this.processText = 'uploading...'
      this.progressing()
      this.$emit('uploadingFile', true)
      // console.log('start send')
      try {
        const { success, data } = await analyseFit(formData)
        // console.log('send finished', success, data)
        if (success) {
          if (data.response_code === 200) {
            this.percentage = 100
            this.resetAction('upload success!')
            this.$emit('uploadedData', data.response_data)
          } else {
            const parseError = this.reponseCodeExplan(data.response_code)
            this.resetAction(parseError)
            this.errorMsg = parseError
          }
        } else {
          this.resetAction('network fail!')
          this.errorMsg = 'network fail!'
        }
      } catch (e) {
        let message = ''
        if (typeof e.response.data === 'undefined') {
          message = 'Cors issue, please check backend service'
        } else {
          message = e.message
        }
        console.log('error', e)
        this.resetAction(message)
        this.errorMsg = message
      }
    },
    reponseCodeExplan (code) {
      switch (code) {
        case 200:
          return 'success'
        case 460:
          return 'records too few'
        case 461:
          return 'GPX format error, duplicate Altitude in same point'
        case 403:
          return 'forbidden'
        case 404:
          return 'not found'
        case 500:
          return 'internal server error'
        default:
          return 'unknown error'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-wrapper {
  h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .note-text{
    font-size: 18px;
    color: #888888;
  }

  .dropZone {
    position: relative;
    width: 380px;
    height: 140px;
    border: 1px dashed #d4d4d4;
    display: flex;
    align-items: center;
    justify-content: center;

    .dropZone-title {
      display: flex;

      span {
        line-height: 36px;
      }
    }

    &:hover {
      border: 1px dashed #E22A29;
      background-color: #FBEEED;
    }

    .dropZone-info {
      .dropZone-title {
        font-size: 16px;
      }
    }

    .hover-info {
      width: 100%;
      color: #E22A29;
      position: absolute;
      text-align: center;
      bottom: -30px;
      left: 0;
      display: none;
    }

    input {
      width: 100%;
      height: 140px;
      position: absolute;
      cursor: pointer;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }
}

.dropZone-over {
  background: #FBEEED;

  .hover-info {
    display: block;
  }
}

.dropZone-uploaded {
  position: relative;
  width: 380px;
  height: 140px;
  border: 1px dashed #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;

  .uploading-status {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;

    span {
      display: block;
      font-size: 16px;
      color: #E22A29;
      text-align: center;
      margin-top: -30px;
    }

    &.show {
      display: block;
    }
  }

  .progress-bar {
    width: calc(100% + 2px);
    height: 5px;
    background: #D4D4D4;
    position: absolute;
    bottom: -5px;

    .progress-bar-inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      background-color: #E22A29;
    }
  }

  .start-btn {
    width: 110px;
    height: 40px;
    background-color: #E22A29;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    position: absolute;
    bottom: -65px;
    left: 0;

    &.disabled {
        background-color: #D4D4D4;
        cursor: not-allowed;
    }
  }
}

.dropZone-uploaded-info {
  display: flex;
  line-height: 30px;
}
</style>
