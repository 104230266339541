import api from '@/services/api'
const url = (action) => `analyse/${action}`

/**
 * @namespace Courses
 */
// TODO 待端待處理：code: 400 / 課程編號錯誤 (取得系統課程標籤/取得自訂課程標籤/取得系列、類別、購課須知)
/**
 *  課程列表
 * @params params.keyword
 */
export async function analyseFit (params) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  const res = await api.post(url('fit'), params, { headers })
  // console.log('res', res)
  const { response, data } = res
  if (response) {
    const { data: ErrorResponse } = response
    // console.log('xxx', ErrorResponse)
    return ErrorResponse // 進入 error 取得 response
  } else return { success: true, data: data } // 正常呼叫
}

export async function analyseGpx (params) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  const res = await api.post(url('gpx'), params, { headers })
  // console.log('res', res)
  const { response, data } = res
  if (response) {
    console.log('response', response)
    // 進入 error 取得 response
    const { data: ErrorResponse, status: statusCode } = response
    return {
      success: false,
      data: {},
      message: ErrorResponse,
      code: statusCode
    }
  } else return { success: true, data: data, message: '', code: 200 } // 正常呼叫
}

export async function updateSuggestion (params) {
  const res = await api.post(url('strategy-update'), params)
  // console.log('res', res)
  const { response, data } = res
  if (response) {
    const { data: ErrorResponse } = response
    return ErrorResponse // 進入 error 取得 response
  } else return { success: true, data: data } // 正常呼叫
}
