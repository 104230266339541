
<template>
  <span>{{ count | NumberComma }}</span>
</template>
<script>
import anime from 'animejs'
export default {
  name: 'AnimateNumber',
  props: {
    value: {
      type: Number,
      default: () => 0
    },
    duration: {
      type: Number,
      default: () => 1000
    }
  },
  data () {
    return {
      num: 1000,
      count: 0
    }
  },
  watch: {
    value (val) {
      this.setNumberAnimate(val)
    }
  },
  mounted () {
    // this.num = this.value
    this.setNumberAnimate(this.value)
  },
  methods: {
    setNumberAnimate (val) {
      const obj = { n: this.count }
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: this.duration,
        easing: 'linear',
        update: () => {
          this.count = obj.n
        }
      })
    }
  }
}
</script>
<style>
</style>
