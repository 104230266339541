<template>
  <div>
    <h3 class="mb-3">Profile <small>(yout can update this information for accurate analysis.)</small></h3>
    <v-card class="profile-card mb-5">
      <v-card-text>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <label>Gender</label>
              <p class="value">
                <select v-model="gender">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </p>
            </v-col>
            <v-col>
              <label>Age</label>
              <p class="value">
                <input type="text" v-model="age">
              </p>
            </v-col>
            <v-col>
              <label>Height</label>
              <p class="value">
                <input type="text" v-model="height"> cm
              </p>
            </v-col>
            <v-col>
              <label>Weight</label>
              <p class="value">
                <input type="text" v-model="weight"> kg
              </p>
            </v-col>
            <v-col>
              <label>Bike Weight</label>
              <p class="value">
                <input type="text" v-model="bikeWeight"> kg
              </p>
            </v-col>
            <v-col>
              <label>Headwind</label>
              <p class="value">
                <input type="text" v-model="windSpeed"> m/s
              </p>
            </v-col>
            <v-col>
              <label>Temperature</label>
              <p class="value">
                <input type="text" v-model="temperature"> ℃
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn
          text
          class="update-btn"
          @click="reAnalyse"
        >
          Update and Analysis
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { analyseFit } from '@/services/api/analyse'
export default {
  name: 'ProfileBlock',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['profileGetter', 'fitFileGetter', 'fitFileNameGetter']),
    gender: {
      get () {
        return this.$store.state.auth.profile.gender
      },
      set (value) {
        this.$store.dispatch('auth/updateGender', value)
      }
    },
    age: {
      get () {
        return this.$store.state.auth.profile.age
      },
      set (value) {
        this.$store.dispatch('auth/updateAge', value)
      }
    },
    height: {
      get () {
        return this.$store.state.auth.profile.height
      },
      set (value) {
        this.$store.dispatch('auth/updateHeight', value)
      }
    },
    weight: {
      get () {
        return this.$store.state.auth.profile.weight
      },
      set (value) {
        this.$store.dispatch('auth/updateWeight', value)
      }
    },
    bikeWeight: {
      get () {
        return this.$store.state.auth.profile.bike_weight
      },
      set (value) {
        this.$store.dispatch('auth/updateBikeWeight', value)
      }
    },
    windSpeed: {
      get () {
        return this.$store.state.auth.profile.wind_speed
      },
      set (value) {
        this.$store.dispatch('auth/updateWindSpeed', value)
      }
    },
    temperature: {
      get () {
        return this.$store.state.auth.profile.temperature
      },
      set (value) {
        this.$store.dispatch('auth/updateTemp', value)
      }
    }
  },
  methods: {
    async reAnalyse () {
      const formData = new FormData()
      formData.append('file', this.fitFileGetter)
      formData.append('member_gender', this.profileGetter.gender)
      formData.append('member_height', this.profileGetter.height)
      formData.append('member_weight', this.profileGetter.weight)
      formData.append('bike_weight', this.profileGetter.bike_weight)
      formData.append('wind_speed', this.profileGetter.wind_speed)
      formData.append('temperature', this.profileGetter.temperature)
      formData.append('FTP', this.profileGetter.FTP)
      formData.append('uploaded_file_name', this.fitFileNameGetter)
      // console.log('sendFile', formData)
      // start processing
      // this.uploading = true
      // this.processText = 'uploading...'
      // this.progressing()
      this.$emit('uploadingFile', true)
      // console.log('start send')
      try {
        const { success, data } = await analyseFit(formData)
        // console.log('send finished', success, data)
        if (success) {
          if (data.response_code === 200) {
            // this.percentage = 100
            // this.resetAction('upload success!')
            this.$emit('uploadedData', data.response_data)
          } else {
            // const parseError = this.reponseCodeExplan(data.response_code)
            // console.log('parseError', parseError')
            // this.resetAction(parseError)
            // this.errorMsg = parseError
          }
        } else {
          // this.resetAction('network fail!')
          // this.errorMsg = 'network fail!'
        }
      } catch (e) {
        let message = ''
        if (typeof e.response.data === 'undefined') {
          message = 'Cors issue, please check backend service'
        } else {
          message = e.message
        }
        console.log('error', message)
        // this.resetAction(message)
        // this.errorMsg = message
      }
    }
  }
}
</script>
<style lang="scss" scoped>
h3{
  font-size:30px;
  small{
    font-size: 16px;
  }
}
.update-btn{
  background-color: #f54b4b !important;
  color:#fff;
  width:100%;
  border-radius: 0 0 4px 4px;
}
.profile-card{
  background:#f4f4f4;
  label{
    font-size: 16px;
    font-weight: bold;
    color:#000;
  }
  .row{
    padding-left:48px;
    padding-right:48px;
  }
  .col{
    padding-left:0;
    padding-right:0;
  }
  input{
    width: 60%;
    border-bottom: 2px solid #000;
    text-align: center;
    &:focus{
      outline: none;
    }
  }
  select{
    width: 60%;
    border-bottom: 2px solid #000;
    text-align: center;
    &:focus{
      outline: none;
    }
  }
}
</style>
