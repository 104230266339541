export const state = () => ({
  sample: {}
})

export const getters = {
  sampleGetter: state => state.sample
}

export const mutations = {
  sampleSetter (state, payload) {
    state.sample = Object.assign({}, payload)
  }
}

export const actions = {
  updateSample ({ commit }, newSample) {
    commit('sampleSetter', newSample)
  }
}
