<template>
  <div class="hello">
    <h1>Posts</h1>
    <ul>
      <li v-for="{ id, title } in postsGetter" :key="id">
        <router-link :to="`/posts/${id}`">{{title}}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    ...mapGetters('example', [
      'sampleGetter'
    ]),
    ...mapGetters('posts', [
      'postsGetter'
    ])
  },
  async created () {
    await this.getAllPosts()
    this.updateSample({
      a: 'a'
    })
  },
  methods: {
    ...mapActions('example', [
      'updateSample'
    ]),
    ...mapActions('posts', [
      'getAllPosts'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
