import { getPosts } from '@/services/api/posts'
export const state = () => ({
  posts: [],
  gettingAllPostsIsLoading: false
})

export const getters = {
  postsGetter: state => state.posts,
  gettingAllPostsIsLoadingGetter: state => state.gettingAllPostsIsLoading
}

export const mutations = {
  postsSetter (state, payload) {
    state.posts = payload
  },
  gettingAllPostsIsLoadingSetter (state, payload) {
    state.gettingAllPostsIsLoading = payload
  }
}

export const actions = {
  async getAllPosts ({ commit }) {
    commit('gettingAllPostsIsLoadingSetter', true)
    try {
      const posts = await getPosts()
      commit('postsSetter', posts)
      commit('gettingAllPostsIsLoadingSetter', false)
    } catch {
      commit('gettingAllPostsIsLoadingSetter', false)
      // 錯誤處理
    }
  }
}
