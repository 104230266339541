<template>
  <v-container class="pa-0 top-area" fluid>
    <v-container class="pa-0 content-wrapper">
      <div class="logo">
        <img src="~@/assets/images/logo.png" alt="logo" />
      </div>
      <div class="context">
        <h2>Ride smarter, not harder with<br>the world's first generative cycling service AI.</h2>
          <p>Our AI-powered tool uses advanced algorithms and machine learning techniques to analyze a cyclist's performance and provides personalized recommendations for the best cycling strategies on other routes to optimize their performance. Whether you're a beginner or an experienced cyclist, our tool can help you achieve your cycling goals.
          </p>
      </div>
    </v-container>
  </v-container>
</template>
<script>

export default {
  name: 'BannerHeader'
}
</script>
<style lang="scss" scoped>
.top-area {
  height: 498px;
  width: 100%;
  position: relative;
  background-image: url('~@/assets/images/banner_bg.png');
  .content-wrapper{
    position: relative;
  }
  .logo{
    margin: 122px 0 0  0;
    img{
      width: 258px;
    }
  }
  .context{
    max-width: 872px;
    h2{
      color:#F74B4B;
      font-size: 22px;
      margin-bottom: 20px;
    }
    p{
      color: #fff;
      font-size: 17px;
    }
  }
}
</style>
