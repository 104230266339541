export const state = () => ({
  loginToken: null,
  profile: {
    gender: 'Male',
    FTP: 100,
    age: 21,
    height: 170,
    weight: 58,
    bike_weight: 6.8,
    wind_speed: 1,
    temperature: 25
  },
  FTP: 100,
  memberLevel: {},
  fitFile: '',
  fitFileName: ''
})

export const getters = {
  loginTokenGetter: state => state.loginToken || '',
  FTPGetter: state => state.FTP || 0,
  profileGetter: state => {
    if (Object.keys(state.profile).length > 0) {
      return state.profile
    } else if (JSON.parse(window.localStorage.getItem('profile'))) {
      return JSON.parse(window.localStorage.getItem('profile'))
    } else return []
  },
  levelGetter: state => state.memberLevel || {},
  fitFileGetter: state => state.fitFile || '',
  fitFileNameGetter: state => state.fitFileName || ''
}

export const mutations = {
  loginTokenSetter (state, loginToken) {
    state.loginToken = loginToken
  },
  profileSetter (state, profile) {
    state.profile = profile
  },
  genderSetter (state, val) {
    state.profile.gender = val
  },
  ageSetter (state, val) {
    state.profile.age = val
  },
  weightSetter (state, val) {
    state.profile.weight = val
  },
  heightSetter (state, val) {
    state.profile.height = val
  },
  bikeWeightSetter (state, val) {
    state.profile.bike_weight = val
  },
  windSpeedSetter (state, val) {
    state.profile.wind_speed = val
  },
  tempSetter (state, val) {
    state.profile.temperature = val
  },
  FTPSetter (state, FTP) {
    state.FTP = FTP
  },
  memberLevelSetter (state, memberLevel) {
    state.memberLevel = memberLevel
  },
  fitFileSetter (state, file) {
    state.fitFile = file
  },
  fitFileNameSetter (state, fileName) {
    state.fitFileName = fileName
  }
}

export const actions = {
  setProfile ({ commit, getters }, profileOjb) {
    const newprofile = Object.assign({}, getters.profileGetter, profileOjb)
    commit('profileSetter', newprofile)
    window.localStorage.setItem('profile', JSON.stringify(newprofile))
  },
  setFTP ({ commit, getters }, FTP) {
    commit('FTPSetter', FTP)
  },
  setMemberLevel ({ commit, getters }, memberLevel) {
    commit('memberLevelSetter', memberLevel)
  },
  updateGender ({ commit, getters }, val) {
    commit('genderSetter', val)
  },
  updateAge ({ commit, getters }, val) {
    commit('ageSetter', val)
  },
  updateHeight ({ commit, getters }, val) {
    commit('heightSetter', val)
  },
  updateWeight ({ commit, getters }, val) {
    commit('weightSetter', val)
  },
  updateBikeWeight ({ commit, getters }, val) {
    commit('bikeWeightSetter', val)
  },
  updateWindSpeed ({ commit, getters }, val) {
    commit('windSpeedSetter', val)
  },
  updateTemp ({ commit, getters }, val) {
    commit('tempSetter', val)
  },
  setFitFile ({ commit, getters }, file) {
    commit('fitFileSetter', file)
  },
  setFitFileName ({ commit, getters }, fileName) {
    commit('fitFileNameSetter', fileName)
  }
}
